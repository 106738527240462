import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import ShowPosts from "../components/ShowPosts"

import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import { Grid, Card, Text } from '@mantine/core';

const IndexPage = ({ data, location }) => {
  const manualPosts1 = data.allContentfulManuals1st.edges;
  const manualPosts2 = data.allContentfulManuals2nd.edges;

  return (
  <Layout>
    <Seo title="Home" />
    <div className="bg-gray">
      <div className="ast-container ast-container-top">
        <div className="post-basic">
          <h2 style={{margin: "2rem 0 3rem", fontSize: 32}}>Cookiebot マニュアル</h2>
          <h3 style={{margin: "2rem 0 3rem", fontSize: 25}}>Cookiebot のインストール</h3>
          <ShowPosts posts={manualPosts1} />
          <hr style={{margin: "4rem 0"}} />
          <ShowPosts posts={manualPosts2} />
        </div>
      </div>
    </div>
  </Layout>
  );
};

export default IndexPage

export const query = graphql`
  query BlogArticleQueryTopSection {
    allContentfulManuals1st: allContentfulManuals(
      limit: 30,
      sort: {fields: updatedAt, order: DESC},
      filter: {
        node_locale: {eq: "en-US"},
        tags: {in: "導入"}
      } ) {
      edges {
        node {
          id
          title
          slug
          tags
          content {
            raw
          }
          contentMarkdown{
            contentMarkdown
            childMarkdownRemark {
              html
              excerpt(format: PLAIN, pruneLength: 100, truncate: true)
            }
          }
          thumbnail{
            title
            gatsbyImageData
          }
          createdAt(formatString: "YYYY-MM-DD")
          updatedAt(formatString: "YYYY-MM-DD")
        }
      }
    }
    allContentfulManuals2nd: allContentfulManuals(
      limit: 30,
      sort: {fields: createdAt, order: DESC},
      filter: {
        node_locale: {eq: "en-US"},
        tags: {nin: "導入"}
      } ) {
      edges {
        node {
          id
          title
          slug
          tags
          content {
            raw
          }
          contentMarkdown{
            contentMarkdown
            childMarkdownRemark {
              html
              excerpt(format: PLAIN, pruneLength: 100, truncate: true)
            }
          }
          thumbnail{
            title
            gatsbyImageData
          }
          createdAt(formatString: "YYYY-MM-DD")
          updatedAt(formatString: "YYYY-MM-DD")
        }
      }
    }
  }
`;